// gatsby-config.js

module.exports = {
  siteMetadata: {
    title: "GrebAcademy",
    siteUrl: "https://www.grebacademy.com",
  },
  plugins: [
    {
      resolve: '@grebban/gatsby-source-storyblok',
      options: {
        accessToken: 'ZMOtRAAenAiY0YstSLC4fgtt',
        homeSlug: 'home',
        version: process.env.NODE_ENV === 'production' ? 'published' : 'published',
        resolveRelations: ['course.lecturers', 'page.listed', 'course_item.course'],
      }
    },
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `images`,
        path: `${__dirname}/src/images`,
      },
    },
    `gatsby-plugin-react-helmet`,
    {
      resolve: `gatsby-plugin-layout`,
      options: {
        component: require.resolve(`./src/components/Layout`)
      }
    },
    {
      resolve: `gatsby-plugin-scroll-reveal`,
      options: {
          threshold: 0, // Percentage of an element's area that needs to be visible to launch animation
          once: true, // Defines if animation needs to be launched once
          disable: false, // Flag for disabling animations
          
          // Advanced Options
          selector: '[data-sal]', // Selector of the elements to be animated
          animateClassName: 'sal-animate', // Class name which triggers animation
          disabledClassName: 'sal-disabled', // Class name which defines the disabled state
          rootMargin: '0% 0%', // Corresponds to root's bounding box margin
          enterEventName: 'sal:in', // Enter event name
          exitEventName: 'sal:out' // Exit event name
      }
    },
    {
      resolve: `gatsby-plugin-styletron`,
      options: {
        // You can pass options to Styletron.
        prefix: "_",
        // Disable dev debug mode, enabled by default
        debug: true,
      },
    },
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: `GrebAcademy`,
        short_name: `GrebAcademy`,
        start_url: `/`,
        icon: `src/assets/icons/favicon.png`,
        display: `standalone`,
        background_color: `#ffffff`,
        theme_color: `#ffffff`
      }
    },
    {
      resolve: "gatsby-plugin-google-tagmanager",
      options: {
        id: "GTM-MW8XHMG",
  
        // Include GTM in development.
        // Defaults to false meaning GTM will only be loaded in production.
        includeInDevelopment: false,
  
        // datalayer to be set before GTM is loaded
        // should be an object or a function that is executed in the browser
        // Defaults to null
        defaultDataLayer: { platform: "gatsby" }
      },
    },
    {
      resolve: `gatsby-plugin-feed`,
      options: {
        query: `
        {
          site {
            siteMetadata {
              title
              description
              siteUrl
              site_url: siteUrl
            }
          }
        }
      `,
        feeds: [
          {
            serialize: ({ query: { site, allStoryblokEntry } }) => {
              return allStoryblokEntry.edges.map(edge => {
                return Object.assign({}, "edge.node.name", {
                  guid: site.siteMetadata.siteUrl + "/" + edge.node.full_slug,
                  title: edge.node.name,
                  url: site.siteMetadata.siteUrl + "/" + edge.node.full_slug,
                  categories: [edge.node.content.topic], 
                  description: JSON.parse(edge.node.content).ingress,
                  custom_elements: [
                    {'media:content': {
                      _attr: {
                        url: JSON.parse(edge.node.content).hero[0].image.replace("//a.storyblok.com/", "https://img2.storyblok.com/"),
                        "xmlns:media": "http://search.yahoo.com/mrss/",
                        type: "image/jpeg"
                      }
                    }},
                  ]
                })
              })  
            },
            query:
            `{
                allStoryblokEntry(filter: {field_component: {eq: "course"}}) {
                  edges {
                    node {
                      id
                      name
                      created_at
                      sort_by_date
                      uuid
                      slug
                      field_component
                      full_slug
                      content
                    }
                  }
                }
              }
              `
            ,
            output: "/rss.xml",
            title: "GrebAcademy",
            description: "Kurser, Föreläsningar, Workshops, Events",
            // optional configuration to insert feed reference in pages:
            // if `string` is used, it will be used to create RegExp and then test if pathname of
            // current page satisfied this regular expression;
            // if not provided or `undefined`, all pages will have feed reference inserted
            match: "^/utbildning/",
            // optional configuration to specify external rss feed, such as feedburner
          },
        ],
      },
    },
  ],
}